<template>
    <div>
        <nav-bar></nav-bar>
        <div>
            <img src="../assets/images/about-us/banner.png" alt="" class="img">
            <div class="to-understand pointer" @click="go('/SeriesDetails')">去了解</div>
        </div>
        <div class="about-us-achievement">
          <div>
            <img src="../assets/images/about-us/girl.png" alt=""  class="about-us-achievement-girl">
          </div>
          <div class="container flex-between">
            <div  v-for="(item,index) in achievement" :key="index" >
              <div>
                <img :src="item.images" alt=""  class="about-us-achievement-icon">
              </div>
              <div class="about-us-achievement-title">{{item.title}}</div>
              <div class="about-us-achievement-line"></div>
              <div class="about-us-achievement-description">{{item.description}}</div>
            </div>
          </div>
        </div>
        <div class="text-center CompanyProfile-bg">
          <div  class="CompanyProfile-title">关于星戟</div>
          <div   class="CompanyProfile-english">- Company Profile -</div>
          <div  class="CompanyProfile-content">
            北京星戟技术有限公司<br>
            坚持服务至上的原则，致力于成为全国智能IDC全链路综合解决方案供应商。 <br>
            北京星戟技术有限公司成立于2018年12月，地处北京市海淀区中关村环保科技园区， 自成立至今，团队成员紧扣“需求驱动自我、科技服务社会”的创业发展理念， 始终紧跟市场需求和世界前沿科技发展动态， 旨在通过技术攻关和科技创新为国家和社会提供更多优质的信息技术产品和解决方案。</div>
        </div>
        <div style="background: #222328;" class="text-center">
          <div class="container">
            <div  class="Service-title">服务行业</div>
            <div  class="Service-english">- Service industry -</div>
            <div  class="Service-content">
              针对现阶段数据中心全链路建设服务周期内出现的诸多问题和市场痛点，以及社会各界对算力、存储力以及网络交换力等日益增长的刚性需求。 <br>
              解决市场中各行业对公有云、私有云、混合云、边缘云构建实施环节 中所存在的建设成本高、建设环境要求高、维护成本高、能源消耗高、 信息安全隐患高、建设效率低下、 智能化水平不高等无法适应社会数字化发展需求等诸多矛盾问题。
            </div>
            <div>
              <img src="../assets/images/about-us/Service.png"  class="Service-img"/>
            </div>
          </div>
        </div>
        <!-- <div class="course">
          <div class="container">
            <div class="course-title">星戟<img src="../assets/images/about-us/diamond.png"/><br>发展历程</div>
            <div class="flex-course">
              <div class="course-content" v-for="(item,index) in course" :key="index" >
                <div>
                  <img src="../assets/images/about-us/diamond.png"/>
                </div>
                <div class="course-time">{{item.time}}</div>
                <div class="course-description">{{item.description}} </div>
              </div>
            </div>
          </div>
          <div>
            <img src="../assets/images/about-us/course-bg-boy.png" class="course-bg-boy"/>
          </div>
        </div> -->
        <Footer></Footer>
    </div>
  </template>
  
  <script scoped>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        achievement: [
          {
            title: "5年",
            description: "专注数据中心领域5年",
            images: require('../assets/images/about-us/HTML5-fill.png'),
          },
          {
            title: "5+",
            description: "荣获5项外观专利",
            images: require('../assets/images/about-us/10.png'),
          },
          {
            title: "100+",
            description: "模块服务行业超过100个",
            images: require('../assets/images/about-us/100.png'),
          }
        ],
        course: [
          {
            time: '2018',
            description:'12月北京星戟技术有限公司成立，地处北京市海淀区中关村环保科技园区。 ',
          },
          {
            time: '2019',
            description:'公司在创始人的带领下，广聚人才，先后与陆军防化学院，中国科学院、中国联通等军队、 政府、科研、企业等进行合作，寻找市场机遇',
          },
          {
            time: '2020',
            description:'通过中关村高新技术企业、国家高新技术企业认定，获得北京市专利新产品证书',
          },
          {
            time: '2021',
            description:'着手致力于超融合IDC模块的研发，是国内首家提出该概念的企业，符合现阶段数据中心发展的低能耗节能减排、去中心化等要求。 ',
          },
          {
            time: '2022',
            description:'通过武器装备科研生产二级保密单位资质认证，打造固安数据集中应用中心，中小企业内部微型 数据中心，城市周边边缘数据中心及城市内部展 销展厅兼企业集中区区域数字中心',
          }
        ]
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
    return {
        ...toRefs(useData),
      goTo,
      go
    }
  }
}
  </script>
  <style scoped>
.about-us-achievement{
  width: 1676px;
height: 448px;
background: #FFFFFF;
border-radius: 12px;
box-shadow: 0px 4px 10px 0px rgba(204,215,241,0.5);
margin: 0 auto;
top: -150px;
position: relative;
z-index: 2;
}
.about-us-achievement-girl{
  width:267px ;height:352px ;position: absolute;right: 0;top: -180px;
}
.about-us-achievement-icon{
  width:96px ;height:96px ;padding: 90px 0 30px;
}
.about-us-achievement-title{
  font-size: 30px;color: #031D5B;font-weight: bold;
}
.about-us-achievement-line{
  width: 80px;
height: 6px;
background: #1FDB84;
margin: 40px 0 30px;
}
.about-us-achievement-description{
  color: #5B6C94;font-size: 22px;
}
.CompanyProfile-bg{
  width:100%;height: 1051px;
    background: url('../assets/images/about-us/CompanyProfile-bg.jpg') no-repeat;
    background-size: cover;
    position: relative;
    top: -170px;
}
.CompanyProfile-title{
  font-size: 36px;padding: 200px 0px 16px;
}
.CompanyProfile-english{
  font-size: 20px;font-weight: bold;color: #1FDB84;
}
.CompanyProfile-content{
  font-size: 26px;color: #32325C;line-height: 60px;width: 1158px;margin: 84px auto 0;
}
.Service-title{
  color: #FFFFFF;font-size: 40px;padding-top: 90px;
}
.Service-english{
  font-size: 20px;color: #FFFFFF;opacity: 0.6;padding: 28px 0px 74px;
}
.Service-content{
  font-size: 30px;color: #FFFFFF;line-height: 60px;
}
.Service-img{
  width:1093px ;display: block;padding:130px 0 ;margin: 0 auto;
}
.to-understand{
  width:268px;height:80px;background:#1fdb84 ;font-size: 26px;text-align: center;line-height: 80px;position: relative;top: -544px;left: 176px;color: #fff;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.to-understand:hover{
  box-shadow:0px 0px 30px 5px rgba(31,219,132,0.2);
  background: #3ddc93;
}
.course{
  width: 100%;
  height: 1008px;
  background: url('../assets/images/about-us/course-bg.png') no-repeat;
  background-size: cover;
  position: relative;
}
.course-title{
  font-size: 36px;color: #313A5A;line-height: 72px;padding:104px 0px 80px ;
}
.course-title img{
  width: 30px;
margin: -30px -30px -1.92px 22.4px;
}
.course-content{
  width:336px ;
  margin: 0px 46px;
}
.course-time{
  font-size: 24px;
  padding-top: 10px;
}
.course-description{
  height:160px ;
  color: #666666;
  line-height: 24px;
  padding-top: 46px;
}
.course-content img{
  width: 30px;
}
.flex-course{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -46px;
}
.course-bg-boy{
  position: absolute;
bottom: -90px;
right: 0px;
width: 629px;
}
</style>
  